import React, { PureComponent } from 'react';
import axios from 'axios';
import Switch from "react-switch";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../i18n.js';
import { addUrlPart } from '../../common/index.js';
import './DocumentTable.css';

class DocumentTable extends PureComponent {

    static defaultProps = {
        identifierName: 'identifier',
    };

    constructor(props) {
        super(props);

        this.state = {
            currPage: 0,
            numPerPage: 15,
            urlCopied: null,
            expandedRows: {}, // Tracks expanded rows
        };

        this.paginate = this.paginate.bind(this);
        this.downloadAndShowFile = this.downloadAndShowFile.bind(this);
    }

    paginate({ selected }) {
        this.setState({
            currPage: selected,
        });
    }

    async downloadAndShowFile(url) {
        const request = {
            method: 'GET',
            url: url,
            responseType: 'blob',
        };

        try {
            const response = await axios(request);
            if (response.data && response.status === 200) {
                const file = new Blob([response.data], { type: response.data.type });
                const fileURL = URL.createObjectURL(file);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            }
        } catch (e) {
            console.log(e);
        }
    }

    renderRows(documents) {
        const { baseUrl } = this.props;
        const { urlCopied, expandedRows } = this.state;
    
        return documents.flatMap((doc, index) => {
            let url = baseUrl;
            url = addUrlPart(url, doc.documentType);
            url = addUrlPart(url, doc.issuer);
            url = addUrlPart(url, doc.wtRegion);
            url = addUrlPart(url, doc.isoCode);
            url = addUrlPart(url, doc.identifier);
    
            const copyLink = urlCopied === url ? (
                <div className='copied'><i>{i18n.t('copied')}</i></div>
            ) : (
                <CopyToClipboard
                    text={url}
                    onCopy={() => this.setState({ urlCopied: url })}
                >
                    <FontAwesomeIcon icon="copy" size="2x" className='copy-icon' />
                </CopyToClipboard>
            );
    
            // Filter versions excluding the published version
            const filteredVersions = doc.versions.filter(
                (version) => version.fileBusinessDate !== doc.publishedVersion.fileBusinessDate && version.status === "Published"
            );
    
            // Main Row
            const mainRow = (
                <tr className={index % 2 === 0 ? 'whiteline' : 'greyline'} key={`row-${doc.id}`}>
                    <td className='break-words'>{doc.documentType}</td>
                    <td>{i18n.t(doc.issuer)}</td>
                    <td>{doc.wtRegion}</td>
                    <td>{doc.isoCode}</td>
                    <td>{doc.identifier}</td>
                    <td>{doc.description}</td>
                    <td>{doc.publishedVersion.fileBusinessDate}</td>
                    <td className='textCenter view-file' onClick={() => this.downloadAndShowFile(url)}>
                        <FontAwesomeIcon icon="eye" size="2x" />
                    </td>
                    <td className='textCenter'>
                        <a href={url} target='_blank' rel="noopener noreferrer">
                            <FontAwesomeIcon icon="file-download" size="2x" />
                        </a>
                    </td>
                    <td className='textCenter view-file'>{copyLink}</td>
                    <td className='textCenter'>
                        {filteredVersions.length > 0 && (
                            <Switch
                                onChange={() =>
                                    this.setState((prevState) => ({
                                        expandedRows: {
                                            ...prevState.expandedRows,
                                            [doc.id]: !prevState.expandedRows[doc.id],
                                        },
                                    }))
                                }
                                checked={!!expandedRows[doc.id]}
                                handleDiameter={21}
                                width={40}
                                height={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor='#05a9b1'
                                offColor='#8c959b'
                            />
                        )}
                    </td>
                </tr>
            );
    
            // Expanded Rows for Versions
            const expandedRowsContent = expandedRows[doc.id]
                ? filteredVersions.map((version, versionIndex) => (
                    <tr key={`expanded-${doc.id}-${versionIndex}`} className="expanded-row">
                        <td colSpan="6"></td>
                        <td>{version.fileBusinessDate}</td>
                        <td className='textCenter view-file' onClick={() => this.downloadAndShowFile(`${url}${version.fileBusinessDate}/`)}>
                            <FontAwesomeIcon icon="eye" size="2x" />
                        </td>
                        <td className='textCenter'>
                            <a href={`${url}${version.fileBusinessDate}/`} target='_blank' rel="noopener noreferrer">
                                <FontAwesomeIcon icon="file-download" size="2x" />
                            </a>
                        </td>
                        <td className='textCenter view-file'>{
                        urlCopied === `${url}${version.fileBusinessDate}/` ? (
                            <div className='copied'><i>{i18n.t('copied')}</i></div>
                                ) : (
                                    <CopyToClipboard
                                        text={`${url}${version.fileBusinessDate}/`}
                                        onCopy={() => this.setState({ urlCopied: `${url}${version.fileBusinessDate}/` })}
                                    >
                                        <FontAwesomeIcon icon="copy" size="2x" className='copy-icon' />
                                    </CopyToClipboard>
                                )
                            }
                        </td>
                        <td colSpan="1"></td>
                    </tr>
                ))
                : null;
    
            return [mainRow, expandedRowsContent];
        });
    }
    
    

    render() {
        const { identifierName, documents } = this.props;
        const { currPage, numPerPage } = this.state;

        const min = currPage * numPerPage < documents.length ? currPage * numPerPage : 0;
        const max = min + numPerPage;

        const docsToShow = documents.slice(min, max);
        const pageCount = Math.ceil(documents.length / numPerPage);

        return (
            <div className="document-table">
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>{i18n.t('document-type')}</th>
                                <th>{i18n.t('issuer')}</th>
                                <th>{i18n.t('region')}</th>
                                <th>{i18n.t('language')}</th>
                                <th>{i18n.t(identifierName)}</th>
                                <th>{i18n.t('description')}</th>
                                <th>{i18n.t('document-date')}</th>
                                <th>{i18n.t('view-file')}</th>
                                <th>{i18n.t('download-file')}</th>
                                <th>{i18n.t('copy-link')}</th>
                                <th>{i18n.t('show-history')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows(docsToShow)}
                        </tbody>
                    </table>
                </div>
                <div className='row-pagination'>
                <ReactPaginate
                        previousLabel={<FontAwesomeIcon icon='chevron-left' size='xs' />}
                        nextLabel={<FontAwesomeIcon icon='chevron-right' size='xs' />}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.paginate}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-item'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'pageLink'}
                        breakLinkClassName={'page-item'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'pageLink'}
                        activeClassName={'active'}
                        disabledClassName={'disabled'}
                    />
                </div>
            </div>
        );
    }
}

export default DocumentTable;
